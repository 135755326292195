<template>
  <div class="main-content">
    <h3 class="page-heading">Admin: Invoices</h3>

    <div class="controls">
      <span class="item">
        <label for="group">Group</label>
        <select name="group" v-model="group">
          <option v-for="group in groups" :key="group">
            {{ group }}
          </option>
        </select>
      </span>
      <span class="item">
        <label for="dateFrom">From</label>
        <input
          type="date"
          name="dateFrom"
          v-model="period.dateFrom"
          @change="update()" />
      </span>
      <span class="item">
        <label for="dateTo">To</label>
        <input
          type="date"
          name="dateTo"
          v-model="period.dateTo"
          @change="update()" />
      </span>
      <span class="item">
        <label for="periods">/ Period</label>
        <select name="periods" @change="selectPeriod">
          <option
            v-for="invoicePeriod in getInvoicePeriods"
            :key="invoicePeriod.id"
            :value="invoicePeriod.id">
            {{ `${invoicePeriod.startDate} - ${invoicePeriod.endDate}` }}
          </option>
        </select>
      </span>
      <span class="item">
        <label>Download Data: </label>
        <button @click="this.downloadTable('csv')">CSV</button>
        <button @click="this.downloadTable('xlsx')">XLSX</button>
      </span>
      <span class="item">
        <label>Download Invoices: </label>
        <button @click="this.downloadInvoices()">ZIP</button>
      </span>
    </div>

    <table v-if="group != 'None'">
      <thead>
        <tr>
          <th></th>
          <th colspan="3">Dates</th>
          <th colspan="2">Company</th>
          <th>Amount</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <template
          v-for="(total, index) in group == 'Operator'
            ? getByOperator
            : getByCompany"
          :key="total.id">
          <tr>
            <td>
              <input type="checkbox" v-model="showExtra[`${group}-${index}`]" />
            </td>
            <td>{{ total.startDate }}</td>
            <td>➡️</td>
            <td>{{ total.endDate }}</td>
            <td>{{ total.code }}</td>
            <td>{{ total.name }}</td>
            <td>{{ this.formatMoney(total.amount) }}</td>
            <td>Unpaid</td>
          </tr>
          <tr v-if="showExtra[`${group}-${index}`]">
            <td colspan="7" align="right">
              <table class="extra">
                <tbody>
                  <tr
                    v-for="invoice in total.invoices"
                    :key="invoice.reference">
                    <td>
                      <a @click="downloadInvoice(invoice.reference)">{{
                        invoice.reference
                      }}</a>
                    </td>
                    <td>
                      {{ transform(humanize(invoice.type), toTitleCase) }}
                    </td>
                    <td v-if="group == 'Operator'">
                      {{
                        this.formatMoney(
                          (invoice.to.code == total.code &&
                            invoice.direction == "Credit") ||
                            (invoice.to.code != total.code &&
                              invoice.direction == "Debit")
                            ? invoice.amount
                            : invoice.amount * -1
                        )
                      }}
                    </td>
                    <td v-else>
                      {{
                        this.formatMoney(
                          ("c" + invoice.to.id == total.code &&
                            invoice.direction == "Credit") ||
                            ("c" + invoice.to.id != total.code &&
                              invoice.direction == "Debit")
                            ? invoice.amount
                            : invoice.amount * -1
                        )
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
              <hr />
            </td>
            <td>
              <!-- payment buttons go here -->
            </td>
          </tr>
        </template>
      </tbody>
    </table>
    <table v-else>
      <thead>
        <tr>
          <th colspan="3">Dates</th>
          <th>Reference</th>
          <th>Type</th>
          <th>Amount</th>
          <th colspan="3">Companies</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="invoice in getAll" :key="invoice.id">
          <td>{{ invoice.startDate }}</td>
          <td>➡️</td>
          <td>{{ invoice.endDate }}</td>
          <td>
            <a @click="downloadInvoice(invoice.reference)">{{
              invoice.reference
            }}</a>
          </td>
          <td>{{ transform(humanize(invoice.type), toTitleCase) }}</td>
          <td>
            {{
              this.formatMoney(
                invoice.direction == "Credit"
                  ? invoice.amount
                  : invoice.amount * -1
              )
            }}
          </td>
          <td>{{ invoice.from?.name ?? "Consumer" }}</td>
          <td>➡️</td>
          <td>{{ invoice.to.name }}</td>
          <td>
            {{ invoice.paid ? "Paid" : "Unpaid" }}
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="this.invoices.length == 0" class="no-records">
      No invoices are available for the current filters.
    </div>
  </div>
</template>

<script>
  import store from "@/store";
  import { useToast } from "vue-toastification";
  import { downloadBlob } from "@tucktrucks/core";
  import { humanize, transform, toTitleCase } from "@alduino/humanizer/string";

  export default {
    data() {
      return {
        group: "None",
        groups: ["None", "Operator", "Company"],
        period: { dateFrom: "2023-11-13", dateTo: "2023-11-19" },
        invoicePeriods: [],
        invoices: [],
        showExtra: [],
        humanize,
        transform,
        toTitleCase,
      };
    },

    watch: {
      period() {
        this.downloadData();
      },
    },

    computed: {
      getAll() {
        let result = this.invoices;
        result = result.sort((a, b) =>
          a.reference > b.reference ? 1 : b.reference > a.reference ? -1 : 0
        );

        return result;
      },

      getByOperator() {
        // Get all the distinct codes, from the from or the to
        const distinctCodes = [
          ...this.invoices.map((x) => x.from?.code ?? ""),
          ...this.invoices.map((x) => x.to.code),
        ]
          .filter((x) => x != "")
          .filter((a, b, c) => c.indexOf(a) == b)
          .sort();

        // Get all the distinct companies so we can reference those.
        const distinctCompanies = distinctCodes.map(
          (x) =>
            [
              ...this.invoices.map((y) => y.from),
              ...this.invoices.map((y) => y.to),
            ]
              .filter((x) => x != null)
              .filter((a, b, c) => c.indexOf(a) == b)
              .filter((y) => y.code == x)[0]
        );

        let response = [];

        distinctCompanies.forEach((company) => {
          var codeInvoices = this.invoices.filter(
            (x) => x.from?.code == company.code || x.to.code == company.code
          );

          const startDate = codeInvoices.map((x) => x.startDate).sort()[0];
          const endDate = codeInvoices
            .map((x) => x.endDate)
            .sort()
            .slice(-1)[0];

          response.push({
            code: company.code,
            name: company.name,
            startDate: startDate,
            endDate: endDate,
            amount: codeInvoices.reduce(
              (accumlated, current) =>
                accumlated +
                ((current.to.code == company.code &&
                  current.direction == "Credit") ||
                (current.to.code != company.code &&
                  current.direction == "Debit")
                  ? current.amount
                  : current.amount * -1),
              0
            ),
            invoices: codeInvoices,
          });
        });

        return response;
      },

      getByCompany() {
        const distinctCompanies = [
          ...this.invoices.map((x) => x.from?.id ?? 0),
          ...this.invoices.map((x) => x.to.id),
        ]
          .filter((x) => x != 0)
          .filter((a, b, c) => c.indexOf(a) == b)
          .sort((a, b) => a - b);

        let response = [];

        distinctCompanies.forEach((companyId) => {
          const company = [
            ...this.invoices.map((y) => y.from),
            ...this.invoices.map((y) => y.to),
          ]
            .filter((x) => x != null)
            .find((x) => x.id == companyId);

          const codeInvoices = this.invoices.filter(
            (x) => x.from?.id == companyId || x.to.id == companyId
          );

          const startDate = codeInvoices.map((x) => x.startDate).sort()[0];
          const endDate = codeInvoices
            .map((x) => x.endDate)
            .sort()
            .slice(-1)[0];

          response.push({
            code: "c" + companyId,
            name: company.name,
            startDate: startDate,
            endDate: endDate,
            amount: codeInvoices.reduce(
              (accumlated, current) =>
                accumlated +
                ((current.to.id == company.id &&
                  current.direction == "Credit") ||
                (current.to.id != company.id && current.direction == "Debit")
                  ? current.amount
                  : current.amount * -1),
              0
            ),
            invoices: codeInvoices,
          });
        });

        return response;
      },

      getInvoicePeriods() {
        const response = this.invoicePeriods.map((x) => x).sort();

        return response;
      },
    },

    methods: {
      update() {
        this.downloadData();
      },

      downloadData() {
        store.state.apiPrivate.client.endpoints.invoices
          .query({
            dateFrom: this.period.dateFrom,
            dateTo: this.period.dateTo,
          })
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            if (response.status == 404) {
              return [];
            }

            return Promise.reject(response);
          })
          .then((data) => {
            this.invoices = data;
          })
          .catch((ex) => {
            if (ex.status >= 400 && ex.status <= 499) {
              window.log.warn(
                "Could not download invoices for these dates.",
                ex
              );

              useToast().warning(
                "Could not download invoices for these dates."
              );
            } else {
              window.log.error("Failed to download invoices.", ex);
              useToast().error("Failed to download invoices.");
            }

            this.invoices = [];
          });
      },

      downloadPeriods() {
        store.state.apiPrivate.client.endpoints.invoicePeriods
          .query({})
          .then((response) => {
            if (response.status == 200) {
              return response.data.data;
            }

            return Promise.reject(response);
          })
          .then((data) => {
            this.invoicePeriods = data ?? [];
            this.period = {
              dateFrom: this.invoicePeriods[0]?.startDate ?? "2023-11-13",
              dateTo: this.invoicePeriods[0]?.endDate ?? "2023-11-19",
            };
          })
          .catch((ex) => {
            window.log.error("Failed to download invoice periods.", ex);
            useToast().error("Failed to download invoice periods.");

            this.invoicePeriods = [];
          });
      },

      selectPeriod(ev) {
        const selectedId = ev.target.selectedOptions[0].value;
        const selectedPeriod = this.invoicePeriods.find(
          (x) => x.id == selectedId
        );

        if (selectedPeriod == null) {
          window.log.error("Could not find a matching period.", ev);
          useToast().error("Could not find a matching period.");
        } else {
          this.period = {
            dateFrom: selectedPeriod.startDate,
            dateTo: selectedPeriod.endDate,
          };
        }
      },

      downloadTable(filetype) {
        store.state.apiPrivate.client.endpoints.invoices
          .query(
            {
              dateFrom: this.period.dateFrom,
              dateTo: this.period.dateTo,
            },
            `download=${filetype}`
          )
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.data; // Single DATA as not using JsonApi
            }

            return Promise.reject(response);
          })
          .then((data) => {
            const mimeType =
              filetype == "xlsx"
                ? "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                : "text/csv";

            downloadBlob(
              data,
              `invoices-${this.period.dateFrom}-to-${this.period.dateTo}.${filetype}`,
              mimeType
            );
          })
          .catch((ex) => {
            window.log.error("Failed to download table of invoices.", ex);
            useToast().error("Failed to download table of invoices.");
          });
      },

      downloadInvoice(reference) {
        store.state.apiPrivate.client.endpoints.invoices
          .download({
            references: [reference],
          })
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.data; // Single DATA as not using JsonApi
            }

            return Promise.reject(response);
          })
          .then((data) => {
            const blob = new Blob([data], { type: "application/pdf" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = reference;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((ex) => {
            window.log.error(`Failed to download invoice '${reference}'.`, ex);
            useToast().error(`Failed to download invoice '${reference}'.`);
          });
      },

      downloadInvoices() {
        store.state.apiPrivate.client.endpoints.invoices
          .download({
            dateFrom: this.period.dateFrom,
            dateTo: this.period.dateTo,
          })
          .then((response) => {
            if (response.status >= 200 && response.status <= 299) {
              return response.data; // Single DATA as not using JsonApi
            }

            return Promise.reject(response);
          })
          .then((data) => {
            const blob = new Blob([data], { type: "application/octet-stream" });
            const link = document.createElement("a");
            link.href = URL.createObjectURL(blob);
            link.download = `invoices-${this.period.dateFrom}-to-${this.period.dateTo}.zip`;
            link.click();
            URL.revokeObjectURL(link.href);
          })
          .catch((ex) => {
            window.log.error("Failed to download invoices.", ex);
            useToast().error("Failed to download invoices.");
          });
      },
    },

    mounted() {
      this.downloadData();
      this.downloadPeriods();
    },
  };
</script>

<styles lang="scss" scoped>
.controls {
  .item {
    padding: 1em;

    label,
    button {
      margin-right: 1em;
    }
  }
}

a {
  cursor: pointer !important;
  color: #ff7575 !important;

  &:hover {
    color: #ffa6a6 !important;
  }
}

.extra {
  color: #666;

  td {
    padding: 0 2em;
  }
}

.no-records {
  padding: 3em;
  font-size: 1.2em;
  text-align: center;
}
</styles>
